import React from "react";
import Header from "../header";
import Banner from "../banner";
import Artigos from "../artigos";
import Topicos from "../topicos";
import Atendimento from "../atendimento";
import Sobre from '../sobre';
import Contato from "../contato";
import Rodape from "../rodape";

function Home() {
    return <div>
        <Header/>
        <Banner/>
        <Topicos/>
        <Artigos/>
        <Atendimento/>
        <Sobre/>
        <Contato/>
        <Rodape/>
    </div>
}

export default Home;