import React from 'react';
import './style.css';

function Artigos(){

    return  <section id="artigos" className="contentgray artigos">
                <div className="container py-4">
                        <div className="row-fluid align-items-center">
                            <div id="page-title">
                            <div id="page-title-inner">
                                <h1><span>ARTIGOS</span></h1>
                            </div>	
                            </div>
                            <div className="clearfix"></div>
                            <hr className="clean"></hr>	
                            </div>   

                        <div className="row align-items-center">
                            <div className="col-lg-4">
                            <div className="thumbnail">
                                <div className="icons-box">
                                    <p><i className="fas fa-camera-retro fa-4x"></i></p>
                                    <h3 className='fs-4'>Terapia Cognitiva Comportamental</h3>
                                    <div>
                                        <p>
                                        Você conhece a Terapia cognitiva comportamental (TCC)?
                                        O psicólogo que trabalha com esta abordagem, tem como princípio que nossos pensamentos ...
                                        </p>
                                    </div>

                                    <div className="widget-footer">
                                        <p><a href="/art01" className="btn btn-outline-secondary">Ler Mais</a>
                            
                                        </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <p></p>
                            </div>

                            <div className="col-lg-4">
                                <div className="thumbnail">
                                <div className="icons-box">
                                    <p><i className="fab fa-odnoklassniki-square fa-4x"></i></p>
                                    <h3 className='fs-4'>Sexualidade</h3>
                                    <div className='mb-4'>
                                    <p>
                                    A sexualidade é um dos pontos de importância das pessoas de modo geral. E, por incrível que pareça a sexualidade ainda 
                                    é pouco conversado entre as pessoas como assunto ...
                                     </p>
                                    </div>
                                    <div className="widget-footer">
                                    <p><a href="/art02" className="btn btn-outline-secondary">Ler Mais</a>
                                    </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                </div>
                                <p></p>
                            </div>

                            <div className="col-lg-4">
                                <div className="thumbnail">
                                <div className="icons-box">
                                    <p><i className="fas fa-eye fa-4x"></i></p>
                                    <h3 className='fs-4'>Autoconhecimento</h3>
                                    <p>
                                    Uma necessidade para nos sentirmos bem é o autoconhecimento que é uma forma 
                                    de reflexão pessoal, onde buscamos identificar nossas características ...</p>
                                    <div className="widget-footer">
                                    <p><a href="/art03"  className="btn btn-outline-secondary">Ler Mais</a>
                                    </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                </div>
                                <p></p>
                            </div>	
                        </div>

                        <div className="clearfix"></div>

                        <div className="row align-items-center">
                            <div className="col-lg-4">
                            <div className="thumbnail">
                                <div className="icons-box">
                                <p><i className="fas fa-theater-masks fa-4x"></i></p>
                                    <h3 className='fs-4'>Desregulação Emocional</h3>
                                    <p>
                                    Podemos dizer que a desregulação emocional é uma dificuldade que a pessoa tem em controlar suas emoções deixando muitas
                                     vezes a impulsividade tomar conta ...</p>
                                    <div className="widget-footer">
                                    <p><a href="/art04" className="btn btn-outline-secondary">Ler Mais</a>
                                    </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <p></p>
                            </div>
                            <div className="col-lg-4">
                            <div className="thumbnail">
                                <div className="icons-box">
                                <p><i className="fas fa-grin-hearts fa-4x"></i></p>
                                    <h3 className='fs-4'>Autoestima</h3>
                                    <p>
                                    Quando se fala de autoestima é bom lembrar que o fato de se conhecer ajuda no processo de desenvolver a autoestima, o que atualmente se percebe o quanto ...</p>
                                    <div className="widget-footer">
                                    <p><a href="/art05" className="btn btn-outline-secondary">Ler Mais</a>
                                    </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <p></p>
                            </div>
                            <div className="col-lg-4">
                            <div className="thumbnail">
                                <div className="icons-box">
                                <p><i className="fas fa-restroom fa-4x"></i></p>
                                    <h3 className='fs-4'>TCC</h3>
                                    <p>
                                    O que significa?
                                    A Terapia Cognitiva Comportamental é uma abordagem da psicologia e trabalha com a hipótese de que os pensamentos interferem  ...</p>
                                    <div className="widget-footer">
                                    <p><a href="/art06" className="btn btn-outline-secondary">Ler Mais</a>
                                    </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <p></p>
                            </div>
                            </div>	
                            
                            <div className="clearfix"></div>


				
                </div>
            </section>;
  }

export default Artigos;