import React from 'react';
import HeaderBack from '../../headerback';
import './style.css';

function Art04(){

    return  <section className='content'>
            <div>
            <HeaderBack/>
            <div className="container mt-5 py-5">
                <div className="row">
                    <div className="col-lg-2">
                    </div>

                    <div className="col-lg-8 marg-art">
                        
                        <h3> DESREGULAÇÃO EMOCIONAL</h3>
                        <p></p>
                        <div className='border-bottom mb-4'></div>
                        <p>
                        Podemos dizer que a desregulação emocional é uma dificuldade que a pessoa tem em controlar suas emoções deixando muitas vezes a impulsividade tomar conta e a pessoa reagir de forma agressiva, ou deixar outras emoções como a raiva, tristeza, frustração, medo angustia dominarem seu momento, suas emoções e assim, afetar seu dia a dia, dificultando se acalmar para compreender o que está acontecendo pois, não consegue refletir e buscar uma resposta mais adaptativa.
                        Devemos buscar treinar a REGULAÇÃO EMOCIONAL que é a capacidade que a pessoa tem de identificar quando algo diferente o afeta e perceber o que está sentindo, identificando qual ou quais emoções estão ali se manifestando e desta forma retornar ao seu equilíbrio, ou seja, não se deixar tomar pelo sentimento que o afetou.
                        É uma habilidade psicológica que devemos desenvolver para podemos administrar melhor nossas emoções assim, evitar que prejudique de forma negativa a pessoa.
                        .
                        </p>
                        <p></p>
                        <a href="/" className="btn btn-outline-secondary btn-art btn-sm">Retornar</a>
                        <p></p>
                        <div className='border-bottom'></div>
                    </div>

                    <div className="col-lg-2">
                    </div>

                </div>
            </div>
            </div>
            </section>;
  }

export default Art04;