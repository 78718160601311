import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/home';
import Art01 from './pages/links/Art01';
import Art02 from './pages/links/Art02';
import Art03 from './pages/links/Art03';
import Art04 from './pages/links/Art04';
import Art05 from './pages/links/Art05';
import Art06 from './pages/links/Art06';

function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/art01' element={<Art01/>}/>
        <Route path='/art02' element={<Art02/>}/>
        <Route path='/art03' element={<Art03/>}/>
        <Route path='/art04' element={<Art04/>}/>
        <Route path='/art05' element={<Art05/>}/>
        <Route path='/art06' element={<Art06/>}/>
      </Routes>
    </Router>
  );
}
export default Rotas;