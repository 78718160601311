import React from 'react';

function Sobre(){

    return  <section id="sobre" className="contentgray">
                <div className="container">
                    <div className="wrapper span12">
                        <div className="row-fluid">
                            <div id="page-title">
                            <div id="page-title-inner">
                                <h1><span>SOBRE MIM</span></h1>
                            </div>	
                            <div className="clearfix"></div>
			                    <hr className="clean"></hr>	
                            </div>
                            <div id="story">
                            <div className="thumbnail p-3">
                                <p className="p-sobre">
                                <cite><b>Maria de Fatima Medeiros Barcelos</b></cite>, formada em Psicologia pela Universidade do Sul de Santa Catarina (UNISUL), campus  Tubarão.
                                </p>
                                <p className="p-sobre">
                                    Atualmente reside em Tubarão-SC onde atua na area de psicologia clinica, com atendimento indivdual e prestação de serviço voluntário em algumas instuiçoes da cidade.
                                </p>
                                <p className="p-sobre">
                                    Atendimento em psicologia clinica à adultos, desde jovens à idosos. O foco de atendimento está voltado ao atendimento individual em TCC - Terapia Cognitiva Comportamental e também como educadora sexual. A base didatica está alicerçada na formação universitaria e em especializações e cursos de aperfeiçoamento, que foram realizadas deste a gradução. Como Educadora Sexual, especialização pela UDESC, fiz vários trabalhos em empresas conversando sobre sexualidade , DST, HIV, AIDS.
                                </p>
					        </div>	
                            </div>
                            <p></p>
                            <div id="history">
                            <div className="thumbnail p-3">    
                               <cite> <h4>Formação e Especialização</h4></cite>
                                <p></p>

                                <p className="p-sobre">
                                    <span className="dropcap">P</span><cite>Psicóloga Clinica </cite> - Formada pela Universidade do Sul de Santa Catarina (UNISUL), campus Tubarão.
                                </p>

                                <p className="p-sobre">
                                    <span className="dropcap color">S</span><cite> Pós Graduação em TCC </cite>- Terapia Cognitiva Comportamental pelo Instituto Catarinense de Terapia Cognitiva – ICTC –  Florianópolis-SC.
                                </p>

                                <p className="p-sobre">
                                    <span className="dropcap dark">I</span><cite> Pós Graduação com</cite> - Especialização em Educação Sexual pela UDESC – Universidade do Estado de Santa Catarina – Florianópolis-SC.
                                </p>

                                <p></p>


                            </div>	
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section>;
  }

export default Sobre;