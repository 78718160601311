import React from "react";
import Logo from '../../assets/logo.png';
import './style.css';

function Header(){
    return  <section id="header">
                <nav className="navbar fixed-top navbar-expand-md navbar-dark">
                <div className="container">
                    <img src={Logo} alt="logo" height="60"/>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#banner">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#artigos">Artigos</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#atendimento">Atendimento</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#sobre">Sobre mim</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#contato">Contato</a>
                        </li>

                    </ul>
                    </div>
                </div>
                </nav>
            </section>;
}
export default Header;