import React from 'react';
import HeaderBack from '../../headerback';
import './style.css';

function Art01(){

    return  <section className='content'>
            <div>
            <HeaderBack/>
            <div className="container mt-5 py-5">
                <div className="row">
                    <div className="col-lg-2">
                    </div>

                    <div className="col-lg-8 marg-art">
                        

                        <h3> TERAPIA COGNITIVA COMPORTAMENTAL </h3>
                        
                        <p></p>
                        <div className='mb-5 border-bottom '></div>
                        <p>
                            Você conhece a Terapia cognitiva comportamental (TCC)?
                            O psicólogo que trabalha com esta abordagem, tem como princípio que nossos pensamentos de alguma forma interferem em nossas reações emocionais, reações físicas e como consequência em nossos comportamentos. 
                            A partir desta abordagem, o psicólogo vai ajudar a pessoa observar o que sente e desta forma ligar ao que passou em sua cabeça, ou seja, o que passou naquele momento que sentiu o desconforto.
                            São momentos delicados, onde a pessoa muitas vezes se percebe confusa com o que acontece não conseguindo fazer esta ligação “pensamentos e a reações emocionais.
                            Na TCC aprendemos que uma situação por si só não é o problema, mas a forma como interpretamos é que vai nos causar o desconforto.
                            O psicólogo tem como propósito ajudar a pessoa a refletir para entender a situação que o deixa envolvido em momentos de tristeza, frustração, raiva, medo ansiedade...
                            É através da psicoterapia que psicólogo e pacientes juntos enfrentam e buscam compreender meios para aliviar as tensões que a pessoa apresenta. 
                        </p>
                        <p></p>
                        <a href="/" className="btn btn-outline-secondary btn-art btn-sm">Retornar</a>
                        <p></p>
                        <div className='mb-5 border-bottom'></div>
                    </div>

                    <div className="col-lg-2">
                    </div>

                </div>
            </div>
            </div>
            </section>;
  }

export default Art01;