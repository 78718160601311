import React from "react";

function Topicos() {
    return  <div className="content"> 
                <div class="container border-top" id="featured-3">
                <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div class="feature col">
                            <div class="feature-icon d-inline-flex align-items-center justify-content-center  fs-2 mb-3">
                                <p><i class="fas fa-question-circle fa-2x"></i></p>
                            </div>
                            <h5 class="fs-2 text-body-emphasis">Porque...</h5>
                            <blockquote><p>Se você se percebe muitas vezes desanimado, uma tristeza que não sabe de onde vem, um vazio no peito e se sente muitas vezes confuso...</p><cite>Pare um pouco e analize...</cite></blockquote>
                    </div>
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center  fs-2 mb-3">
                            <p><i className="fas fa-search fa-2x"></i></p>
                            </div>
                                <h3 class="fs-2 text-body-emphasis">Procurar...</h3>
                                <blockquote><p>Estas situações podem estar surgindo devido ao seu trabalho, questões familiares, sexo, relacionamento entre pais, filhos, irmãos, cônjuges, colegas...</p><cite>Se você se encaixa em..</cite></blockquote>
                        </div>
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center  fs-2 mb-3">
                            <p><i className="fas fa-heartbeat fa-2x"></i></p>
                        </div>
                            <h3 class="fs-2 text-body-emphasis">Um Psicólogo...</h3>
                            <blockquote><p>Autoconhecimento é fundamental para que possamos nos compreender e assim tornar nosso dia a dia mais confortável e menos desgastante.</p><cite>Algum destes itens...</cite></blockquote>
                    </div>
                </div>
                 </div>
            </div>


}

export default Topicos;