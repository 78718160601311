import React from 'react';
import HeaderBack from '../../headerback';
import './style.css';

function Art06(){

    return  <section className='content'>
            <div>
            <HeaderBack/>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-2">
                    </div>

                    <div className="col-lg-8 marg-art">
                        

                        <h3> TCC</h3>
                        <p></p>
                        <div className='border-bottom mb-3'></div>
                        <p>
                        O que significa?
                        </p>
                        <p>
                        A Terapia Cognitiva Comportamental é uma abordagem da psicologia e trabalha com a hipótese de que os pensamentos interferem nas reações emocionais, físicas e comportamentais da pessoa.
                        </p>
                        <p>
                        A terapia cognitiva comportamental ajuda a pessoa a refletir sobre os pensamentos que estão causando seu desconforto emocional, físico e que estão de alguma forma prejudicando, interferindo no comportamento.
                        </p>
                        <p>
                        Esses pensamentos são chamados de PENSAMENTOS AUTOMÁTICOS pois, se manifestam sem que a pessoa busque por ele, são intrusivos. Geralmente as pessoas acreditam e não duvidam da veracidade do que está passando ou passou em sua cabeça no momento que sentiu seu mal-estar. São PENSAMENTO AUTOMÁTICO NEGATIVO porque causam grande desconforto como angústia, tristeza, frustração, raiva, culpa, medo, nojo, enfim são muitas as sensações que esse tipo de pensamento pode causar na pessoa.
                        </p>
                        <p>
                        Esses pensamentos automáticos negativos estão ligados as crenças que a pessoa traz no decorrer de sua vida pois, são eles (PAN) que levam a pessoa a interpretar cada situação que surgem no dia a dia. Sendo assim, não é a situação que causa o desconforto, mas sim a forma como cada um interpreta o momento, ou seja, a situação.
                        </p>
                        <p>
                        Nas sessões de psicoterapia, o psicólogo junto com o paciente, a partir de sua história, no que está acontecendo no momento vai levá-lo a perceber que os sintomas apresentados estão ligados a Erros de Cognição (erros de pensamento) e que através da psicoterapia vai trabalhar meios de corrigir tais pensamentos e buscar alívio de sintomas com técnicas que o profissional ensinará ali na sessão.
                        </p>
                        <p>
                        Essas crenças são construídas ao longo da vida principalmente na infância e adolescência e continuam atualmente, porém, esquecemos que muitas daquelas verdades construídas no desenvolvimento, hoje não se encaixam mais na vida que estamos levando, precisando questionar estas verdades para encontrar respostas mais adaptativas e saudáveis a cada momento a cada situação.
                        </p>
                        <p>
                        Através da psicoterapia o psicólogo vai apresentar ferramentas para que a pessoas (paciente) vá aos poucos percebendo que o mal-estar daquele momento está ligado a um pensamento que ele teve e que causou o desconforto ou seja, um pensamento disfuncional.
                        </p>
                        <p>
                        Uma das ferramentas e que é muito importante é a pessoa conseguir Identificar, Avaliar o pensamento através de sua reflexão e encontrar Respostas mais adaptativas para o alivio do sintoma.

                        </p>
                        <p></p>
                        <a href="/" className="btn btn-outline-secondary btn-art btn-sm">Retornar</a>
                        <p></p>
                        <div className='border-bottom'></div>
                    </div>

                    <div className="col-lg-2">
                    </div>

                </div>
            </div>
            </div>
            </section>;
  }

export default Art06;