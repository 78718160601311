import React from 'react';
import HeaderBack from '../../headerback';
import './style.css';

function Art02(){

    return  <section className='content art02'>
            <div>
            <HeaderBack/>
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                    </div>

                    <div className="col-lg-8 marg-art">
                        

                        <h3> SEXUALIDADE </h3>
                        <p></p>
                        <div className="border-bottom mb-4"></div>
                        <p>
                        A sexualidade é um dos pontos de importância das pessoas de modo geral. E, por incrível que pareça a sexualidade ainda é pouco conversado entre as pessoas como assunto de grande relevância ao nosso dia a dia devido à falta de intimidade com o assunto, conhecimento conosco mesmo e parceria. 
                        Devemos nos perguntar: Até onde conhecemos nossas respostas sexuais, medos, fantasias? 
                        Quando falamos de sexualidade observamos o quanto é importante o autoconhecimento pois, é aonde vamos nos descobrindo e percebendo nossas repostas. 
                        Falar de sexualidade ainda é nos dias de hoje um tabu, inclusive dentro de nossas casas. Lembrando: quando não educamos nossos filhos para a sexualidade, já estamos educando, ou seja, deixamos de passar a informação de forma correta e saudável.
                        A sexualidade de cada um de nós é construída no decorrer do desenvolvimento, incluindo a educação sexual que cada um recebe. É onde encontramos em cada pessoa suas crenças com relação a sexualidade. Percebemos que muitas das crenças que temos com relação ao sexo está ligada a mitos da sexualidade que foram construídas ao longo do tempo. Muitas pessoas apresentam problemas voltados a sua sexualidade por não pararem para refletir sobre o que está levando a acreditar e não questionar ou não buscar uma resposta mais adaptativa a sua situação sexual.
                        Sobre os muitos da sexualidade assunto que pode ser conversado e esclarecido em sessão de psicoterapia. 
                        </p>
                        <p></p>
                        <a href="/" className="btn btn-outline-secondary btn-art btn-sm">Retornar</a>
                        <p></p>
                        <div className="border-bottom"></div>
                    </div>

                    <div className="col-lg-2">
                    </div>

                </div>
            </div>
            </div>
            </section>;
  }

export default Art02;