import React from "react";
import Foto from '../../assets/foto.png';
import './style.css';

function Banner() {
    return <div id="banner" className="content">
                <div class="container p-md-2 mt-4 text-body-emphasis d-flex justify-content-between align-items-center">
                    <div class="col-lg-8 mt-5">
                        <h1 class="display-4 fst-italic">Maria de Fátima Medeiros Barcelos</h1>
                        <br></br>
                        <p class="lead my-3 fs-4 fst-italic">Psicóloga Clínica, sexualidade, educadora sexual</p>
                        <p class="lead my-3 fs-4 fst-italic">Psicoterapeuta - Terapia Congnitiva Comportamental</p>
                        <p class="lead my-3 fs-4 fst-italic">Atendimento somente adultos.</p>
                    </div>
                    <div class="col-lg-4 d-flex justify-content-end align-items-center">
                    <img src={Foto} alt="..."/>
                    </div>
                </div>


            </div>
}

export default Banner;