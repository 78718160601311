import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './style.css';

const Contato = () => {
    const form = useRef();
    const [sucesso, setSucesso] = useState();

     const sendEmail = (e) => {
        e.preventDefault();
        setSucesso('');
        emailjs.sendForm('service_b740g43', 'template_jauz1qa', e.target, {
          publicKey: 'CGT8OyuVP1RTuQnw7',
        })
        .then((result) => {
            setSucesso('S');
          console.log(result.text);
        }, (error) => {
            setSucesso('N');
          console.log(error.text);
        });
        e.target.reset()
    };


    return(   <section id="contato"  className="content contato">    
                <div className="container">
                    <div className="row-fluid align-items-center">
                            <div id="page-title-grey">
                            <div id="page-title-grey-inner">
                                <h1><span>CONTATO</span></h1>
                            </div>	
                            </div>
                            <div className="clearfix"></div>
                            <hr className="clean"></hr>	
                            <p></p>
                    </div>  
                    <div className="row">
                        <div className="col-lg-3">
						
                        </div>
                        <div className="col-lg-6">
                        <div className="thumbnail p-4">	
                            <cite><b>Psicóloga: Maria de Fátima M. Barcelos</b></cite> •
                                Av. Marcolino Martins Cabral •
                                Tubarão, SC - Brasil •
                                Telefone (48) 99933-3362 • <cite><b>Contato direto pelo</b></cite>  <a href="https://api.whatsapp.com/send?l=pt&phone=5548999333362" target="_blank"> <img src="../img/iconwats.png" alt="psi" /></a> •
                             <cite> Se desejar mais informações, preencha o formulário abaixo.</cite>
                            <p>   </p>
                            <form ref={form} onSubmit={sendEmail} className="row g-3">
                                <div className="col-md-12">
                                <label htmlFor="inputMensagem" className="form-label"></label>
                                    <input type="text" className="form-control" id="inputNome" placeholder="Seu nome" name="nome"/>
                                </div>
                                <div className="col-md-12">
                                    <input type="email" className="form-control" id="inputEmail" placeholder="Email válido" name="email"/>
                                </div>
                                <div className="col-12">
                                   
                                    <input type="text" className="form-control" id="inputTelefone" placeholder="Telefone" name="fone"/>
                                </div>
                                <div className="col-12">
                                   
                                    <input type="text" className="form-control" id="inputAssunto" placeholder="Assunto" name="assunto"/>
                                </div>
                                <div className="col-12">
                                    
                                    <textarea className="form-control alt-mensagem" id="inputMensagem" rows="8" placeholder="O que deseja saber?" name="mensagem"></textarea>

                                </div>
                                <div className="col-12">
                                <div className="widget-footer2">
                                    <button type="submit" className="btn btn-outline-secondary">Enviar Mensagem</button>
                                    {sucesso === 'S' ? <div className="alert alert-success mt-3" role="alert">Mensagem enviada com sucesso!</div> : null}
                                    {sucesso === 'N' ? <div className="alert alert-danger mt-3" role="alert">Erro ao enviar Mensagem!</div> : null}
                                </div>
                                </div>
                            </form>
                        </div>
                        </div>
                        <div className="col-lg-3">
						
                        </div>
                    </div>
                    <div className="clearfix"></div>


                </div>  
            </section>);
  }

export default Contato;