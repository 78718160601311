import React from 'react';
import './style.css';

function Atendimento(){

    return  <section id="atendimento" className="content">    
                <div className="container atendimento">
                    <div className="row-fluid align-items-center">
                            <div id="page-title-grey">
                            <div id="page-title-grey-inner">
                                <h1><span>ATENDIMENTO</span></h1>
                            </div>	
                            </div>
                            <div className="clearfix"></div>
                            <hr className="clean"></hr>	
                    </div>   

                    <div id="portfolio-wrapper" className="row align-items-center">
                        <div className="col-lg-4">
                        <div className="thumbnail">		
                                <img src="../img/portfolio/desanimo.jpg" alt="01" className="img-thumbnail"/>
                                <div className="item-description alt">
                                    <h5>DESÂNIMO</h5>
                                    <p>
                                        <span>Se você se percebe muitas vezes desanimado, uma tristeza que não sabe de onde vem, um vazio no peito e se sente muitas vezes confuso...</span>
                                    </p>
                                </div>	
                        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="thumbnail">
                            <img src="../img/portfolio/relacionamento.jpg" alt="02" className="img-thumbnail"/>
                                <div className="item-description alt">
                                    <h5>RELACIONAMENTO</h5>
                                    <p>
                                        Estas situações podem estar surgindo devido ao seu trabalho, questões familiares, relacionamento entre pais, filhos, irmãos, cônjuges, colegas... 
                                    </p>
                                </div>
				        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="thumbnail">
                            <img src="../img/portfolio/autoconhecimento.jpg" alt="03" className="img-thumbnail"/>
                                <div className="item-description alt">
                                    <h5>AUTOCONHECIMENTO</h5>
                                    <p>
                                        Autoconhecimento é fundamental para que possamos nos compreender e assim tornar nosso dia a dia mais confortável e menos desgastante... 
                                    </p>
                                </div>
				        </div>
                        </div>

                    </div>
                    <div className="clearfix"></div>
			        <hr className="clean"></hr>	
                    <div id="story">
						<p>
							Nos dias atuais com a correria não tiramos tempo para muitas coisas, inclusive para nós mesmos. E, de repente nos deparamos com situações que antes não estavam nos incomodando, passavam despercebidas por falta de atenção a nós mesmos.
							A sociedade nos dias de hoje vem exigindo das pessoas em geral as vezes mais do que elas podem dar. Para acompanhar as exigências impostas a pessoa esquece de si mesma e vai atrás daquilo que no momento acredita ser a necessidade do momento.
							Então, quando menos se espera, estamos perdidos, confusos, estressados, ansiosos e com problemas de relacionamento em casa, no trabalho e sem saber o que fazer para sair desta situação.
							Se você se encontra ou se percebe em situações parecidas com essas, talvez seja o momento de estar buscando ajuda do profissional da psicologia.
							Entre em contato conosco, poderemos marcar um horário e conversar. <cite> <b>ESTOU TE AGUARDANDO!!!!!</b></cite></p>

				    </div>	
                    <div className="clearfix"></div>
			        <hr className="clean"></hr>	

                    <div id="portfolio-wrapper" className="row align-items-center">
                        <div className="col-lg-4">
                        <div className="thumbnail">		
                            <img src="../img/portfolio/estresse.jpg" alt="04" className="img-thumbnail"/>
                                <div className="item-description alt">
                                    <h5>STRESS</h5>
                                    <p>
                                        <span>Sentir-se ansioso em alguns momentos do dia muitas vezes é normal. Devemos nos preocupar quando a ansiedade começa a acarretar problemas em nossa saúde física e emocional. Irritação, falta de paciência, taquicardia, insônia, humor alterado podem ser sinais de que algo não está indo bem.</span> 
                                    </p>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </div>	
                        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="thumbnail">
                            <img src="../img/portfolio/sexualidade.jpg" alt="05" className="img-thumbnail"/>
                                <div className="item-description alt">
                                    <h5>SEXUALIDADE</h5>
                                    <p>
                                    Conceitos simples como o que é SEXO, o que é SEXUALIDADE para muitos é difícil de responder, por se tratar de um tema que não é vivenciado de forma natural pelos educadores (família, escola). Este é um assunto que trabalho com pequenos grupos de educadores (pais, professores) no consultório. Se você tem interesse de conversar sobre este assunto me liga. CONTATO (48)-99933-3362. 
                                    </p>
                                </div>
				        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="thumbnail">
                            <img src="../img/portfolio/tdha.jpg" alt="06" className="img-thumbnail"/>
                                <div className="item-description alt">
                                    <h5>TDAH</h5>
                                    <p>
                                    O TDAH é um transtorno que surge na infância. Apesar de muitas pessoas não perceberem ou não aceitarem o TDAH acompanha o indivíduo na idade adulta, trazendo problemas que os mesmos não sabem o porquê de se sentirem desta “forma”. São as COMORBIDADES e elas precisam ser trabalhadas para que se compreenda o que está acontecendo. Mesmo os adultos precisam de atenção e tratamento.  
                                    </p>
                                </div>
				        </div>
                        </div>

                    </div>
                    <div className="clearfix"></div>      
                </div>
            </section>;
  }

export default Atendimento;