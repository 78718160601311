import React from 'react';
import  './style.css';

function Rodape(){
  var ano = new Date().getFullYear(); 

    return <section id="rodape" className="contentgray rodape">    
      <div>
            <ul className="list-unstyled list-inline social text-center">
            <li className="list-inline-item"><a target="_blank" href="#"><i className="fa-cog fab fa-facebook-square fa-2x facebook"></i></a></li>
                <li className="list-inline-item"><a href="https://api.whatsapp.com/send?l=pt&phone=5548999333362"  target="_blank"><i className="fa-cog fab fa-whatsapp-square fa-2x wattsapp"></i></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/psicologafatimabarcelos/" target="_blank"><i className="fa-cog fab fa-instagram-square fa-2x instagran"></i></a></li>						
            </ul>
	    </div>
     <cite> <p> Desenvolvido por Projecon Sistemas - {ano}</p></cite>
      <div className="clearfix"></div>
    </section>;
  }

export default Rodape;

/*
<li className="list-inline-item"><a target="_blank" href=""><i className="fa-cog fab fa-facebook-square fa-2x"></i></a></li>

*/