import React from 'react';
import HeaderBack from '../../headerback';
import './style.css';

function Art05(){

    return  <section className='content'>
            <div>
            <HeaderBack/>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-2">
                    </div>

                    <div className="col-lg-8 marg-art">
                        

                        <h3>AUTOESTIMA</h3>
                        <p></p>
                        <div className='border-bottom mb-3'></div>
                        <p>
						Para podermos viver bem, precisamos nos sentir valorizados e respeitados, em primeiro lugar, por nós mesmos. É daí que nasce a confiança em nossa capacidade de SER e AGIR diante das outras pessoas e do mundo.
                        A confiança em nós depende da imagem que fazemos de nós mesmos, e está intimamente ligada à nossa AUTO ESTIMA.
                        Uma AUTO ESTIMA positiva diante da vida cria em nós um desejo natural de amadurecer, fortalece nossa capacidade de amar e nos ajuda a manter relacionamentos mais maduros e equilibrados.
                        A pessoa que não gosta de si mesma é insatisfeita, tem pensamentos e sentimentos de inferioridade, sente-se ameaçada e sempre assume atitudes de defesa. 
                        A pessoa que se gosta, enfrenta a vida com mais segurança e tranquilidade para encontrar soluções mais adequadas nas diferentes situações que a vida oferece. Além disso, tem uma imagem realista de si mesma, de seus defeitos e qualidades e se relaciona de forma positiva com os outros.”
                        Do livro Auto Estima- Se gosto de mim, posso ajudar mais ao outro. Pastoral da Criança- Organismo de Ação Social de CNBB.
                        </p>
                        <p></p>
                        <a href="/" className="btn btn-outline-secondary btn-art btn-sm">Retornar</a>
                        <p></p>
                        <div className='border-bottom'></div>
                    </div>

                    <div className="col-lg-2">
                    </div>

                </div>
            </div>
            </div>
            </section>;
  }

export default Art05;