import React from 'react';
import HeaderBack from '../../headerback';
import './style.css';

function Art03(){

    return  <section className='content'>
            <div>
            <HeaderBack/>
            <div className="container mt-5 py-5">
                <div className="row">
                    <div className="col-lg-2">
                    </div>

                    <div className="col-lg-8 marg-art">
                        

                        <h3> AUTOCONHECIMENTO</h3>
                        
                        <p></p>
                        <div className='mb-5 border-bottom'></div>
                        <p>
                        Uma necessidade para nos sentirmos bem é o autoconhecimento que é uma forma de reflexão pessoal, onde buscamos identificar nossas características para assim nos entendermos melhor. 
                        Paramos para pensar: No que gosto de fazer? Ou o que não gosto de fazer? Ou o que gostaria de fazer, mas não faço? E por que não faço? São questionamentos simples que nos levam a uma reflexão sobre nosso EU. Nos conhecendo, podemos trabalhar melhor nosso dia a dia compreendendo e tendo uma visão de futuro, do que queremos e até refletir melhor nossas reações emocionais que muitas vezes agimos na impulsividade. Lembrando que ao falar ou pensar sobre autoconhecimento, vamos contribuir com nossa autoestima, relacionamentos, desde parceria, pais e filhos, a família no geral e comigo mesmo.
                        a.
                        </p>
                        <p></p>
                        <a href="/" className="btn btn-outline-secondary btn-art btn-sm">Retornar</a>
                        <p></p>
                        <div className='border-bottom'></div>
                    </div>

                </div>
            </div>
            </div>
            </section>;
  }

export default Art03;